@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-inter: "Inter", sans-serif;
  --font-lexend: "Lexend", sans-serif;
}

body {
  font-family: var(--font-inter);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-lexend);
}
